    <!-- 社区养老 -->
<template>
  <div class="shequ">
    <div>
      <div class="hospitalBox" v-for="item in list" :key="item">
        <div style="width:110px;margin: 15px;">
          <img src="../../assets/banner.jpg" alt="">
        </div>
        <div style="width:180px">
          <div style="position: absolute;top: 10px;">{{item.CPName}}</div>
          <div style="font-size:14px;margin:4px 0px">{{item.Address}}</div>
          <div style="font-size:14px">电话:{{item.LeaderTel}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {WeGetCommunityList} from "@/api/Pension";
export default {
  data(){
    return{
      list:[],
    }
  },
  created(){
    this.CommunityList();
  },
  methods:{
    CommunityList:function(){
      WeGetCommunityList({page:1,limit:99}).then((res)=>{
        console.log(res);
        this.list= res.data.data;
      })
    },
  },
}
</script>
<style>
.shequ {
  background: #ededed;
  height: 100%;
}
.hospitalBox {
  position: relative;
  width: 90%;
  margin: 15px 5%;
  background: #fff;
  border-radius: 15px;
}
.hospitalBox div {
  display: inline-block;
}
.hospitalBox div:nth-child(1) img {
  width: 100%;
  height: 80px;
}
.hospitalBox div:nth-child(2) {
}
</style>