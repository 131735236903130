import request from '@/utils/request'
// 微信获取老人录入健康信息列表
export function WePageHealthyList(query) {
    return request({
        url: 'Pension/WePageHealthyList',
        method: 'get',
        params: query
    })
}
// 微信录入老人健康信息
export function WeSaveHealthy(data) {
    return request({
        url: 'Pension/WeSaveHealthy',
        method: 'post',
        data: data
    })
}
// 微信删除老人健康信息
export function WeDeleteHealthy(data) {
    return request({
        url: 'Pension/WeDeleteHealthy',
        method: 'post',
        data: data
    })
}
// 微信获取老人体检记录
export function WePagePhysicalsList(query) {
    return request({
        url: 'Pension/WePagePhysicalsList',
        method: 'get',
        params: query
    })
}
// 微信录入老人体检记录
export function WeSavePhysicals(data) {
    return request({
        url: 'Pension/WeSavePhysicals',
        method: 'post',
        data: data
    })
}
// 微信删除老人体检记录
export function WeDeletePhysicals(data) {
    return request({
        url: 'Pension/WeDeletePhysicals',
        method: 'post',
        data: data
    })
}
// 小程序获取养老社区列表
export function WeGetCommunityList(query) {
    return request({
        url: 'Pension/WeGetCommunityList',
        method: 'get',
        params: query
    })
}